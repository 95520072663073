import {
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { addDocument, addDocumentToRoot, getDocuments } from '../../api/api';
import ReactQuill from 'react-quill';
import { serverTimestamp } from 'firebase/firestore';

function DiscussionsAdd({
  currentUser,
  setCurrentUser,
  isOpen,
  setIsOpen,
  discussion,
}) {
  const [isEnabled, setIsEnabled] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  const onClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (discussion) {
      setTitle(discussion?.title);
      setContent(discussion?.content);
    }
  }, [discussion]);

  useEffect(() => {
    if (title?.length > 1 && content?.length > 5) {
      setIsEnabled(true);
    }
  }, [title, content]);

  const handleFormSubmit = async () => {
    const discussionObject = {
      id: discussion?.id || uuidv4(),
      title,
      date: discussion?.date || new Date().toLocaleString(),
      timestamp: discussion?.timestamp || serverTimestamp(),
      content,
      author: currentUser?.displayName,
      authorId: discussion?.authorId || currentUser?.id,
      authorPhotoURL: currentUser?.photoURL,
    };
    //const newCurrentUser = await adddiscussion(currentUser, discussionObject);
    const newCurrentUser = await addDocumentToRoot(
      currentUser,
      'discussions',
      discussionObject
    );
    setCurrentUser(newCurrentUser);
  };

  return (
    <div id="labs-add">
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{discussion ? 'Edit' : 'Add New'} Topic</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={() => handleFormSubmit}>
              <Stack spacing={4}>
                <FormControl isRequired>
                  <FormLabel>Title</FormLabel>
                  <Input
                    placeholder="Enter title..."
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    bg={'gray.100'}
                    border={0}
                    _placeholder={{
                      color: 'gray.500',
                    }}
                    className={title?.length > 0 ? 'filled' : ''}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Content</FormLabel>
                  <ReactQuill
                    theme="snow"
                    value={content}
                    onChange={setContent}
                  />
                </FormControl>
              </Stack>
              <Button
                isDisabled={!isEnabled}
                fontFamily={'heading'}
                mt={4}
                w={'full'}
                bgGradient="linear(to-r, red.400,pink.400)"
                color={'white'}
                _hover={{
                  bgGradient: 'linear(to-r, red.400,pink.400)',
                  boxShadow: 'xl',
                }}
                onClick={() => handleFormSubmit()}
              >
                Submit
              </Button>
            </form>
            <br />
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default DiscussionsAdd;
