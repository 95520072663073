import {
  getDocuments,
  getRootDocument,
  getRootDocuments,
  updateRootDocument,
} from './api';

export const getAllUsers = async (currentUser, setAdminData) => {
  if (currentUser?.type !== 'admin') return;
  const data = await getRootDocuments(currentUser, 'users');

  const responses = await Promise.all(
    data.map(async d => ({
      ...d,
      id: d.uid || d.id,
      labs: (await getLabs(d.id)) || [],
      patients: (await getPatients(d.id)) || [],
    }))
  );

  setAdminData(responses);

  /* data.forEach(async (d, index) => {
    const dd = {
      ...d,
      id: d.uid || d.id,
      labs: (await getLabs(d.id)) || [],
      patients: (await getPatients(d.id)) || [],
    };
    await completedData.push(dd);
    if (index === data.length - 1) {
      setAdminData(completedData);
    }
  }); */
};

export const getCertifiedMemberProfiles = async (currentUser, setter) => {
  if (!currentUser) return;
  const res = await getRootDocuments(currentUser, 'users');
  const certifiedOnly = res.filter(
    d => d.membership === 'Certified' && !d.ignore
  );
  const data = certifiedOnly.map(d => ({
    cv: d.cv,
    address: d.address,
    city: d.city,
    state: d.state,
    type: d.type,
    specialty: d.specialty,
    id: d.id,
    photoURL: d.photoURL || d.photoURL,
    employer: d.employer,
    displayName: d.displayName,
  }));

  setter(data);
};

export const getLabs = async id => {
  const labs = await getDocuments(id, 'labs');
  return labs;
};

export const getPatients = async id => {
  const patients = await getDocuments(id, 'patients');
  return patients;
};

export const getAllLabs = async (currentUser, setter) => {
  if (currentUser?.type !== 'admin') return;
  const data = await getRootDocuments(currentUser, 'users', setter);
};

export const getUser = async (currentUser, id, setAdminUser) => {
  if (currentUser?.type !== 'admin') return;
  const data = await getRootDocument(currentUser, 'users', id, setAdminUser);
};

export const updateAdminUser = async (currentUser, id, docObject, setter) => {
  if (currentUser?.type !== 'admin') return;
  const data = await updateRootDocument(
    currentUser,
    'users',
    docObject,
    id,
    setter
  );
};
