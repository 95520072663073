import { Button, Stack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminLabs from './AdminLabs';
import { getAllUsers, getAllLabs } from '../../api/adminApi';
import AdminUsers from './AdminUsers';
import AdminPatients from './AdminPatients';

const tabs = [
  { id: 0, title: 'Users', link: '/admin/users' },
  {
    id: 1,
    title: 'Open Applications',
    link: '/admin/open',
  },
  { id: 2, title: 'Labs', link: '/admin/labs' },
  { id: 3, title: 'Patients', link: '/admin/patients' },
];
function Admin({ currentUser, type }) {
  const navigate = useNavigate();
  const [formattedTabs, setFormattedTabs] = useState(null);
  const [adminUserData, setAdminUserData] = useState(null);

  useEffect(() => {
    getAllUsers(currentUser, setAdminUserData);
  }, []);

  useEffect(() => {
    const fTabs = tabs.map(t => {
      if (t.title === type) {
        t.selected = true;
      } else {
        t.selected = false;
      }
      return t;
    });
    setFormattedTabs(fTabs);
  }, [type]);

  return (
    <div id="admin">
      <Stack
        direction="row"
        spacing={0}
        paddingTop={0}
        paddingBottom={0}
        align="center"
        borderBottom={'1px solid #ddd'}
      >
        {tabs.map(t => (
          <Button
            onClick={() => navigate(t.link)}
            borderRadius={0}
            colorScheme="blue"
            variant={t.selected ? 'solid' : 'ghost'}
          >
            {t.title}
          </Button>
        ))}
      </Stack>
      {type === 'Users' && (
        <AdminUsers currentUser={currentUser} data={adminUserData} />
      )}
      {type === 'Labs' && (
        <AdminLabs currentUser={currentUser} data={adminUserData || []} />
      )}
      {type === 'Patients' && (
        <AdminPatients currentUser={currentUser} data={adminUserData || []} />
      )}
      {type === 'Open Applications' && (
        <AdminUsers
          applicationsOnly={true}
          currentUser={currentUser}
          data={adminUserData}
        />
      )}
    </div>
  );
}

export default Admin;
