import {
  addDoc,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  collection,
  getDocs,
  deleteDoc,
  getCountFromServer,
} from 'firebase/firestore';
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { authFire, fireStore } from './fire';

export const getUserDataByUserID = async (setCurrentUser, idFromAdmin) => {
  const user = authFire.currentUser;
  const id = idFromAdmin || user.uid;
  if (id) {
    const docRef = doc(fireStore, 'users', id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setCurrentUser && setCurrentUser(docSnap.data());
      return docSnap.data();
    } else {
      //create user
      console.log('no such user.');
    }
  }
};

export const checkIfFbUserThenSetDb = async (currentUser, setCurrentUser) => {
  const user = authFire.currentUser;

  if (user?.uid) {
    const docRef = doc(fireStore, 'users', user.uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      if (currentUser) return;
      setCurrentUser(docSnap.data());
      return docSnap.data();
    } else {
      //create user
      const userToSave = {
        id: user?.uid,
        displayName: user?.displayName,
        email: user.email,
        emailVerified: user?.emailVerified,
        isAnonymous: user.isAnonymous,
        paypal: '',
        mobile: user.phoneNumber,
        photoURL: user?.photoURL || user?.photoUrl || '',
      };
      await setDoc(doc(fireStore, 'users', user?.uid), userToSave);
      setCurrentUser(userToSave);
    }
  }
};

export const updateBio = async (
  currentUser,
  bio,
  displayName,
  setCurrentUser
) => {
  const res = await setDoc(
    doc(fireStore, 'users', currentUser.id),
    {
      bio,
      displayName,
    },
    { merge: true }
  );
  setCurrentUser({ ...currentUser, bio, displayName });

  return res;
};

export const signNDA = async (
  currentUser,
  setCurrentUser,
  todaysDate,
  navigate
) => {
  console.log('currentUser', currentUser.uid, currentUser.id);
  const res = await setDoc(
    doc(fireStore, 'users', currentUser.uid || currentUser.id),
    {
      signedNDA: todaysDate,
    },
    { merge: true }
  );
  setCurrentUser({ ...currentUser, signedNDA: todaysDate });
  navigate('/onboarding');

  return res;
};

export const applyForCertification = async (
  currentUser,
  bio,
  mobile,
  specialty,
  employer,
  type,
  licenseNumber,
  address,
  city,
  state,
  zip
) => {
  if (
    currentUser?.membership !== 'Premium' ||
    currentUser?.membership !== 'Certified'
  ) {
    const applicationDate = new Date().toLocaleDateString('en-US');
    const res = await setDoc(
      doc(fireStore, 'users', currentUser.id || currentUser.uid),
      {
        bio,
        mobile,
        specialty,
        employer,
        membership: 'Applied',
        applicationDate,
        type,
        licenseNumber,
        address,
        city,
        state,
        zip,
        displayName: currentUser?.displayName,
      },
      { merge: true }
    );
    if (res)
      return {
        ...currentUser,
        bio,
        mobile,
        specialty,
        employer,
        applicationDate,
        address,
        city,
        state,
        zip,
      };
  }
};

export const addDocumentToRoot = async (currentUser, docTitle, docObject) => {
  const res = await setDoc(doc(fireStore, docTitle, docObject.id), docObject, {
    merge: true,
  });
  if (res)
    return {
      ...currentUser,
    };
};

export const addCollectionItemToDocumentRoot = async (
  currentUser,
  docTitle,
  docId,
  collectionTitle,
  collectionObject
) => {
  /*   console.log(
    'api',
    `${docTitle}/${docId}/${collectionTitle}/${collectionObject.id}`
  ); */

  const res = await addDoc(
    collection(fireStore, docTitle, docId, collectionTitle),
    collectionObject
  );
  if (res)
    return {
      ...currentUser,
    };
};

const getCount = async id => {
  const coll = collection(fireStore, `discussions/${id}/comments`);
  const snapshot = await getCountFromServer(coll);
  //console.log('count: ', snapshot.data().count);
  return snapshot.data().count;
};

export const getRootDocuments = async (currentUser, docTitle, setter) => {
  if (currentUser) {
    const res = await getDocs(collection(fireStore, docTitle));

    if (res) {
      const resArray = [];
      res.forEach(async (doc, index) => {
        const d = doc.data();
        const count = getCount(doc.id);
        resArray.push({ ...d, count });
      });
      if (setter) {
        setter(resArray);
      }

      return resArray;
    }
  }
};

export const getRootDocumentCollections = async (
  currentUser,
  docTitle,
  docId,
  collectionTitle,
  setter
) => {
  if (currentUser) {
    const res = await getDocs(
      collection(fireStore, docTitle, docId, collectionTitle)
    );

    if (res) {
      const resArray = [];
      await res.forEach(doc => {
        const d = doc.data();
        resArray.push(doc.data());
      });
      setter(resArray);
      return resArray;
    }
  }
};

export const addDocument = async (
  currentUser,
  docTitle,
  docObject,
  adminSelectedUserId
) => {
  const res = await setDoc(
    doc(
      fireStore,
      'users',
      adminSelectedUserId || currentUser.id,
      docTitle,
      docObject.id
    ),
    docObject,
    { merge: true }
  );
  if (res)
    return {
      ...currentUser,
      labs: [...currentUser.labs, docObject],
    };
};

export const getDocuments = async (id, docTitle, setter) => {
  if (!id) return;
  const res = await getDocs(collection(fireStore, 'users', id, docTitle));
  if (res) {
    const resArray = [];
    await res.forEach(doc => {
      resArray.push(doc.data());
    });
    if (resArray.length > 0) {
      if (setter) {
        setter(resArray);
      }

      return resArray;
    }
  }
};

export const getDocument = async (id, docTitle, docId, setter) => {
  const res = await getDocs(collection(fireStore, 'users', id, docTitle));
  //if (res) console.log('res', res);
  await res.forEach(doc => {
    if (doc.id === docId) {
      setter(doc.data());
    }
  });
};

export const updateRootDocument = async (
  currentUser,
  docTitle,
  docObject,
  docId,
  setter
) => {
  if (currentUser) {
    const res = await setDoc(doc(fireStore, docTitle, docId), docObject);
    if (res) {
      setter(res);
      return {
        ...currentUser,
      };
    }
  }
};

export const addNewCommentToDiscussion = async (
  currentUser,
  docId,
  commentObject
) => {
  if (currentUser) {
    const res = await setDoc(
      doc(fireStore, 'discussions', docId, 'comments', commentObject?.id),
      commentObject
    );
    if (res)
      return {
        ...currentUser,
      };
  }
};

export const editComment = async (
  currentUser,
  setCurrentUser,
  discussionId,
  commentObject
) => {
  if (currentUser.id === commentObject.authorId) {
    const res = await setDoc(
      doc(
        fireStore,
        'discussions',
        discussionId,
        'comments',
        commentObject?.id
      ),
      commentObject
    );
    if (res)
      return {
        currentUser,
      };
    setCurrentUser(currentUser);
  }
};

export const deleteComment = async (
  currentUser,
  setCurrentUser,
  discussionId,
  commentObject
) => {
  if (currentUser.id === commentObject.authorId) {
    const res = await deleteDoc(
      doc(fireStore, 'discussions', discussionId, 'comments', commentObject?.id)
    );
    if (res)
      return {
        currentUser,
      };
    setCurrentUser(currentUser);
  }
};

export const getRootDocument = async (currentUser, docTitle, docId, setter) => {
  if (currentUser) {
    const docRef = doc(fireStore, docTitle, docId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const d = docSnap.data();
      setter(d);
    }
  }
};

/* export const getDocuments = async (currentUser, docTitle, setter) => {
  const res = await getDocs(
    collection(fireStore, 'users', currentUser.id, docTitle)
  );
  if (res) {
    const resArray = [];
    await res.forEach(doc => {
      resArray.push(doc.data());
    });
    setter(resArray);
  }
}; */

export const deleteDocument = async (
  id,
  docTitle,
  docId,
  route,
  navigate,
  isRoot
) => {
  if (isRoot) {
    const res = await deleteDoc(doc(fireStore, docTitle, docId));
    navigate(route);
  } else {
    const res = await deleteDoc(doc(fireStore, 'users', id, docTitle, docId));
    navigate(route);
  }
};

export const updateProfilePicUrl = async (
  currentUser,
  photoURL,
  setCurrentUser
) => {
  const res = await setDoc(
    doc(fireStore, 'users', currentUser.id),
    {
      photoURL,
    },
    { merge: true }
  );
  setCurrentUser({ ...currentUser, photoURL });
  return res;
};

export const updateProfilePic = async (currentUser, file, setCurrentUser) => {
  const storage = getStorage();

  // Create the file metadata
  const metadata = {
    contentType: 'image/jpeg',
  };

  // Upload file and metadata to the object 'images/mountains.jpg'
  const storageRef = ref(storage, `${currentUser?.id}/images/${file.name}`);
  const uploadTask = uploadBytesResumable(storageRef, file, metadata);

  // Listen for state changes, errors, and completion of the upload.
  uploadTask.on(
    'state_changed',
    snapshot => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
      }
    },
    error => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;

        // ...

        case 'storage/unknown':
          // Unknown error occurred, inspect error.serverResponse
          break;
      }
    },
    () => {
      // Upload completed successfully, now we can get the download URL
      getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
        console.log('File available at', downloadURL);
        updateProfilePicUrl(currentUser, downloadURL, setCurrentUser);
      });
    }
  );
};

/* export const getLabs = async (currentUser, setLabs) => {
  const res = await getDocs(
    collection(fireStore, 'users', currentUser.id, 'labs')
  );
  if (res) console.log('res', res);
  const labArray = [];
  await res.forEach((doc, index) => {
    labArray.push(doc.data());
  });
  setLabs(labArray);
}; */

/* export const getLab = async (currentUser, labId, setLab) => {
  const res = await getDocs(
    collection(fireStore, 'users', currentUser.id, 'labs')
  );
  if (res) console.log('res', res);
  await res.forEach(doc => {
    if (doc.id === labId) {
      setLab(doc.data());
    }
  });
}; */

/* export const addLab = async (currentUser, labObject) => {
  const res = await setDoc(
    doc(fireStore, 'users', currentUser.id, 'labs', labObject.id),
    labObject,
    { merge: true }
  );
  if (res)
    return {
      ...currentUser,
      labs: [...currentUser.labs, labObject],
    };
}; */
