import React, { useEffect, useMemo, useState } from 'react';
import { Alert, AlertIcon, ChakraProvider, theme } from '@chakra-ui/react';
import { fire } from './api/fire';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { checkIfFbUserThenSetDb } from './api/api';
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import Navigation from './Layouts/Navigation/Navigation';
import BecomeCertified from './Pages/BecomeCertified/BecomeCertified';
import Landing from './Pages/Landing/Landing';
import Footer from './Layouts/Footer/Footer';
import Pricing from './Pages/Pricing/Pricing';
import MicrobiomeMeeting from './Pages/MicrobiomeMeeting/MicrobiomeMeeting';
import ScrollToTop from './Helpers/ScrollToTop';
import Contact from './Pages/Contact/Contact';
import SignUp from './Pages/SignUp/SignUp';
import CertifiedMembers from './Pages/CertifiedMembers/CertifiedMembers';
import Register from './Pages/Register/Register';
import Login from './Pages/Login/Login';
import NavigationAuthed from './Layouts/Navigation/NavigationAuthed';
import Profile from './Pages/Profile/Profile';
import Logout from './Pages/Logout/Logout';
import EditProfile from './Pages/Profile/EditProfile';
import Labs from './Pages/Labs/Labs';
import LabsDetail from './Pages/Labs/LabsDetail';
import PatientsDetail from './Pages/Patients/PatientsDetail';
import Patients from './Pages/Patients/Patients';
import Discussions from './Pages/Discussions/Discussions';
import DiscussionsDetail from './Pages/Discussions/DiscussionsDetail';
import Loader from './Components/Loader/Loader';
import Onboarding from './Pages/Onboarding/Onboarding';
import Admin from './Pages/Admin/Admin';
import AdminUser from './Pages/Admin/AdminUser';
import ResetPassword from './Pages/Login/ResetPassword';
import './App.scss';
import Privacy from './Pages/Legal/Privacy';
import Terms from './Pages/Legal/Terms';
import About from './Pages/Company/About';
import Press from './Pages/Company/Press';
import Conference from './Pages/Conference/Conference';
import ConferenceWatchVideo from './Pages/Conference/ConferenceWatchVideo';
import ConferenceSignUp from './Pages/Conference/ConferenceSignUp';
import ConferenceSignUpTwo from './Pages/Conference/ConferenceSignUpTwo';
import ConferenceSuccess from './Pages/Conference/ConferenceSuccess';
import ConferenceEmail from './Pages/Conference/ConferenceEmail';
import NDA from './Pages/NDA/NDA';
import AdminUserPatient from './Pages/Admin/AdminUserPatient';
import AdminUserLab from './Pages/Admin/AdminUserLab';
import CertifiedMembersDetail from './Pages/CertifiedMembers/CertifiedMembersDetail';

const auth = getAuth(fire);

function App() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [currentUser, setCurrentUser] = useState(null);
  const [fbUser, setFbUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const isCertified = useMemo(() => {
    if (
      currentUser?.membership === 'Certified' ||
      currentUser?.membership === 'Premium'
    ) {
      return true;
    } else {
      return false;
    }
  }, [currentUser]);

  onAuthStateChanged(auth, user => {
    //console.log('authchange', auth, user);
    if (!user) return;
    if (currentUser) return;
    if (user) {
      setFbUser(user);
      checkIfFbUserThenSetDb(currentUser, setCurrentUser);

      return;
    } else {
      setCurrentUser(null);
    }
    return;
  });

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    if (currentUser && !currentUser.signedNDA) {
      navigate('/sign-nda');
    }
  }, [currentUser, pathname]);

  useEffect(() => {
    checkIfFbUserThenSetDb(currentUser, setCurrentUser);
  }, [currentUser]);

  if (isLoading) return <Loader />;

  return (
    <ChakraProvider theme={theme}>
      <div id="app">
        {currentUser
          ? !pathname.includes('onboarding') &&
            !pathname.includes('conference') && (
              <>
                <NavigationAuthed
                  currentUser={currentUser}
                  isCertified={isCertified}
                />
                {(!currentUser.bio || !currentUser.photoURL) &&
                  currentUser.signedNDA && (
                    <Alert status="warning" fontWeight={800}>
                      <AlertIcon />
                      Please
                      <Link
                        to="/profile/edit"
                        style={{
                          marginLeft: 5,
                          marginRight: 5,
                          textDecoration: 'underline',
                        }}
                      >
                        {!currentUser.photoURL && 'upload a photo'}{' '}
                        {!currentUser.photoURL && !currentUser.bio && 'and '}
                        {!currentUser.bio && 'fill in your bio'}
                      </Link>
                      to complete your profile.
                    </Alert>
                  )}
              </>
            )
          : !pathname.includes('conference') && <Navigation />}
        <ScrollToTop />
        <Routes>
          {currentUser && (
            <>
              <Route
                path="/profile"
                element={<Profile currentUser={currentUser} fbUser={fbUser} />}
              />
              <Route
                path="/sign-nda"
                element={
                  <NDA
                    currentUser={currentUser}
                    setCurrentUser={setCurrentUser}
                    fbUser={fbUser}
                  />
                }
              />
              <Route
                path="/profile/:id"
                element={<Profile currentUser={currentUser} fbUser={fbUser} />}
              />
              <Route
                path="/profile/edit"
                element={
                  <EditProfile
                    currentUser={currentUser}
                    fbUser={fbUser}
                    setCurrentUser={setCurrentUser}
                  />
                }
              />
              <Route
                path="/certified-members"
                element={<CertifiedMembers currentUser={currentUser} />}
              />
              <Route
                path="/certified-members/:id"
                element={<CertifiedMembersDetail currentUser={currentUser} />}
              />
              <Route path="/register" element={<Register />} />
              <Route
                path="/become-certified"
                element={
                  <BecomeCertified
                    currentUser={currentUser}
                    setCurrentUser={setCurrentUser}
                    isCertified={isCertified}
                  />
                }
              />
              <Route
                path="/meetings"
                element={<MicrobiomeMeeting currentUser={currentUser} />}
              />
              <Route
                path="/patients"
                element={
                  <Patients
                    currentUser={currentUser}
                    setCurrentUser={setCurrentUser}
                    isCertified={isCertified}
                  />
                }
              />
              <Route
                path="/patient/:id"
                element={
                  <PatientsDetail
                    currentUser={currentUser}
                    setCurrentUser={setCurrentUser}
                  />
                }
              />
              <Route
                path="/biome-chat"
                element={
                  <Discussions
                    currentUser={currentUser}
                    setCurrentUser={setCurrentUser}
                  />
                }
              />
              <Route
                path="/biome-chat/:id"
                element={
                  <DiscussionsDetail
                    currentUser={currentUser}
                    setCurrentUser={setCurrentUser}
                  />
                }
              />
              <Route
                path="/labs"
                element={
                  <Labs
                    currentUser={currentUser}
                    setCurrentUser={setCurrentUser}
                    isCertified={isCertified}
                  />
                }
              />
              <Route
                path="/onboarding"
                element={
                  <Onboarding
                    currentUser={currentUser}
                    setCurrentUser={setCurrentUser}
                  />
                }
              />
              <Route
                path="/lab/:id"
                element={
                  <LabsDetail
                    currentUser={currentUser}
                    setCurrentUser={setCurrentUser}
                  />
                }
              />
              {currentUser.type === 'admin' && (
                <>
                  <Route
                    path="/admin"
                    element={<Admin type="Labs" currentUser={currentUser} />}
                  />
                  <Route
                    path="/admin/labs"
                    element={<Admin type="Labs" currentUser={currentUser} />}
                  />
                  <Route
                    path="/admin/patients"
                    element={
                      <Admin type="Patients" currentUser={currentUser} />
                    }
                  />
                  <Route
                    path="/admin/users"
                    element={<Admin type="Users" currentUser={currentUser} />}
                  />
                  <Route
                    path="/admin/open"
                    element={
                      <Admin
                        type="Open Applications"
                        currentUser={currentUser}
                      />
                    }
                  />
                  <Route
                    path="/admin/user/:id"
                    element={
                      <AdminUser
                        currentUser={currentUser}
                        setCurrentUser={setCurrentUser}
                      />
                    }
                  />
                  <Route
                    path="/admin/user/:id/patient/:patientId"
                    element={
                      <AdminUserPatient
                        currentUser={currentUser}
                        setCurrentUser={setCurrentUser}
                      />
                    }
                  />
                  <Route
                    path="/admin/user/:id/lab/:labId"
                    element={
                      <AdminUserLab
                        currentUser={currentUser}
                        setCurrentUser={setCurrentUser}
                      />
                    }
                  />
                  <Route
                    path="/admin/applications"
                    element={
                      <Admin type="Applications" currentUser={currentUser} />
                    }
                  />
                </>
              )}
              <Route
                path="/logout"
                element={<Logout setCurrentUser={setCurrentUser} />}
              />
              {isCertified ? (
                <Route
                  path="/"
                  element={
                    <Patients
                      currentUser={currentUser}
                      setCurrentUser={setCurrentUser}
                      isCertified={isCertified}
                    />
                  }
                />
              ) : (
                <Route
                  path="/"
                  element={
                    <>
                      {/* <Discussions
                      currentUser={currentUser}
                      setCurrentUser={setCurrentUser}
                      isCertified={isCertified}
                    /> */}
                      <MicrobiomeMeeting currentUser={currentUser} />
                    </>
                  }
                />
              )}
            </>
          )}
          {!currentUser && (
            <>
              <Route
                path="/sign-up"
                element={<SignUp setCurrentUser={setCurrentUser} />}
              />
              <Route
                path="/sign-in"
                element={<Login setCurrentUser={setCurrentUser} />}
              />
              <Route path="/reset-password" element={<ResetPassword />} />

              <Route path="/pricing" element={<Pricing />} />
              <Route path="/meetings" element={<MicrobiomeMeeting />} />
              <Route path="/certified-members" element={<CertifiedMembers />} />
              <Route path="/become-certified" element={<BecomeCertified />} />

              <Route
                path="/logout"
                element={<Logout setCurrentUser={setCurrentUser} />}
              />
              <Route path="/" element={<Landing />} />
            </>
          )}
          <Route path="/conference" element={<Conference />} />
          <Route path="/conference/register" element={<ConferenceEmail />} />
          <Route
            path="/conference/watchvideo"
            element={<ConferenceWatchVideo />}
          />
          <Route
            path="/conference/signup"
            element={
              <ConferenceSignUp
                setCurrentUser={setCurrentUser}
                currentUser={currentUser}
                isCertified={isCertified}
              />
            }
          />
          <Route
            path="/conference/become-certified"
            element={
              <ConferenceSignUpTwo
                setCurrentUser={setCurrentUser}
                currentUser={currentUser}
                isCertified={isCertified}
              />
            }
          />
          <Route
            path="/conference/success"
            element={<ConferenceSuccess setCurrentUser={setCurrentUser} />}
          />
          <Route path="/about" element={<About />} />
          <Route path="/press" element={<Press />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
        </Routes>
        {!pathname.includes('onboarding') &&
          !pathname.includes('conference') && <Footer />}
      </div>
    </ChakraProvider>
  );
}

export default App;
